import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Dialog from "@client/components/lead-form/components/dialog";

type Props = {
  children: React.ReactNode;
  dialog?: string;
  dialogHeader?: string;
  legend: string | React.ReactNode;
};

export default function LeadFormFieldset({
  children,
  dialog,
  dialogHeader,
  legend,
}: Props) {
  return (
    <fieldset className="form__fieldset">
      <div className="form__fieldset__header">
        <legend className="form__legend">
          {typeof legend === "string" ? (
            <span className="form__legend__text">{legend}</span>
          ) : (
            legend
          )}
          {dialog && (
            <FontAwesomeIcon icon={faCircleInfo} height={12} width={12} />
          )}
        </legend>
        {dialog && <Dialog headerText={dialogHeader} text={dialog} />}
      </div>
      <div className="form__fieldset__body">{children}</div>
    </fieldset>
  );
}

"use client";

import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";

import Button from "@ui/js/components/form/button";
import Fieldset from "@ui/js/components/form/fieldset";

export default function LoanTiming() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="How quickly do you need a loan?">
      <Input type="hidden" name="loanTiming" id="loan-timing" />
      <Button
        chevron={true}
        text="Within 48 hours"
        onClick={() => setFieldValue("loanTiming", "Within 48 hours")}
      />
      <Button
        chevron={true}
        text="Within 2 weeks"
        onClick={() => setFieldValue("loanTiming", "Within 2 weeks")}
      />
      <Button
        chevron={true}
        text="Within 1 month"
        onClick={() => setFieldValue("loanTiming", "Within 1 month")}
      />
      <Button
        chevron={true}
        text="Unsure, just browsing options"
        onClick={() =>
          setFieldValue("loanTiming", "Unsure, just browsing options")
        }
      />
    </Fieldset>
  );
}

"use client";

import Image from "next/image";

import {
  TRUST_PILOT_BUSINESS_UNIT_ID,
  TRUST_PILOT_URL,
} from "@shared/constants";

import TrustBoxContainer from "@client/components/trust-pilot";

type StepFooterParams = {
  text?: string;
  showBBBWidget?: boolean;
  trustPilotEnabled?: boolean;
};

export default function LeadFormStepFooter({
  text,
  showBBBWidget = true,
  trustPilotEnabled = false,
}: StepFooterParams) {
  const trustPilotBusinessId: string = TRUST_PILOT_BUSINESS_UNIT_ID;
  const trustPilotURL: string = TRUST_PILOT_URL;

  const showTrustPilotWidget: boolean =
    trustPilotBusinessId !== "" && trustPilotURL !== "" && trustPilotEnabled;

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: text || "It's free and will not affect your credit score",
        }}
      ></span>
      {showTrustPilotWidget && <TrustBoxContainer />}
      {showBBBWidget && (
        <a
          href="https://www.bbb.org/us/il/chicago/profile/financial-services/billdoctor-org-0654-1000037486/#sealclick"
          target="_blank"
          rel="nofollow"
        >
          <Image
            height="28"
            width="133"
            className="border-none h-7 mx-auto w-auto"
            src="https://seal-chicago.bbb.org/seals/blue-seal-200-42-bbb-1000037486.png"
            alt="BillDoctor.org BBB Business Review"
          />
        </a>
      )}
    </>
  );
}

"use client";

import Currency from "@client/components/formik/currency";
import Field from "@client/components/formik/field";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";

export default function IncomeAmount() {
  return (
    <>
      <Fieldset
        dialog="Understanding your annual income allows us to recommend financial solutions best suited to your economic standing. List your total available income including wages, retirement, investments, and rental properties. Alimony, child support or separate maintenance is optional and does not need to be included if you do not wish it to be considered as a basis for repaying a loan. Increase any non-taxable income or benefits by 25%."
        dialogHeader="What do we mean by annual income?"
        legend="What is your annual income?"
      >
        <Field label="Income Amount" name="incomeAmount">
          <Currency name="incomeAmount" />
        </Field>
      </Fieldset>
      <Button />
      <div className="form__consents">
        List your total available income including wages, retirement,
        investments, and rental properties. Alimony, child support or separate
        maintenance is optional and does not need to be included if you do not
        wish it to be considered as a basis for repayment. Increase any
        non-taxable income or benefits by 25%.
      </div>
    </>
  );
}

type Props = React.LabelHTMLAttributes<HTMLLabelElement> & {
  label: string;
};

export default function label({ htmlFor, label }: Props) {
  return (
    <label htmlFor={htmlFor} className="form__label">
      {label}
    </label>
  );
}

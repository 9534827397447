import { useEffect, useState } from "react";

import deepReplace from "@shared/functions/deepReplace";
import { logError } from "@shared/functions/log";

import DataLayer from "@client/classes/data-layer/data-layer";

function determineText(fallback: string, override?: string) {
  if (!fallback) {
    throw new Error("Blow up my program because this should never happen.");
  }

  const userData = DataLayer.user;
  if (!userData) {
    logError({
      message: "User data unavailable",
    });
    return fallback;
  }

  if (override?.includes("{{")) {
    const keysPresentInOverrideWithValues = Object.keys(userData).filter(
      (key) => userData[key] && override.includes(key),
    );

    if (keysPresentInOverrideWithValues.length > 0) {
      return deepReplace(override, userData) as string;
    } else {
      logError({
        message: "ButterCMS variable value not present in user data",
      });

      return fallback;
    }
  } else if (override) {
    return override;
  }

  return fallback;
}

export function useCMSOverride(fallback: string, override?: string) {
  // will only be undefined if there is an error that blows up the program
  const [text, setText] = useState<string>();

  useEffect(() => {
    setText(determineText(fallback, override));
  }, []);
  return text;
}

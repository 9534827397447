"use client";

import { FieldAttributes, useField, useFormikContext } from "formik";

import Field from "@ui/js/components/form/field";

export default function LeadFormField(props: FieldAttributes<any>) {
  const [_, meta] = useField(props);
  const { status } = useFormikContext();

  let infoMessage = "";
  if (typeof window != "undefined") {
    if (window?.document.querySelector(".form__field_state_warning")) {
      infoMessage =
        "The entered email appears to be invalid, please verify it is correct.";
    } else {
      infoMessage = "";
    }
  }

  const error =
    meta.error ||
    (status === "error" &&
      "An error occurred while processing your request. Please try again.") ||
    (status === "warning" && infoMessage);

  // The warning status is currently only triggered by Neverbounce

  const state = meta.error ? "error" : status;

  return <Field {...props} message={error} state={state} />;
}

"use client";

import { useFormikContext } from "formik";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

export default function TeachingLevel() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("teachingLevel", e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="What level do you teach?">
        <Field label="Teaching Level" name="teachingLevel">
          <Select name="teachingLevel" onChange={handleChange}>
            <option value="" disabled>
              Select a Level
            </option>
            <option value="preschool">Preschool</option>
            <option value="elementary">Elementary</option>
            <option value="middle_school">Middle School</option>
            <option value="junior_high">Junior High</option>
            <option value="high_school">High School</option>
            <option value="college">College</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}

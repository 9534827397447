"use client";

import { Field, useFormikContext } from "formik";

import Fieldset from "@client/components/lead-form/components/fieldset";

import Button from "@ui/js/components/form/button";

export default function PaymentSituation() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset
      dialog="Knowing how you handle payments helps us create a consolidation plan that fits your financial situation and goals, making it easier to get rid of your debts efficiently. If you're only paying the minimum or less than the minimum, it may mean you need a plan which lowers your monthly payment and your overall debt amount."
      legend="How have you been paying on your debt recently?"
    >
      <Field type="hidden" name="paymentSituation" id="payment-situation" />
      <Button
        chevron={true}
        text="Paying above the minimums"
        onClick={() => setFieldValue("paymentSituation", "payments_in_full")}
      />
      <Button
        chevron={true}
        text="Paying the minimums"
        onClick={() => setFieldValue("paymentSituation", "pay_only_minimum")}
      />
      <Button
        chevron={true}
        text="Not keeping up with the minimums"
        onClick={() => setFieldValue("paymentSituation", "cant_pay_minimum")}
      />
    </Fieldset>
  );
}

"use client";

import {
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
} from "@ariakit/react";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  headerText?: string;
  text: string;
};

export default function LeadFormDialog({ headerText, text }: Props) {
  return (
    <DialogProvider>
      <DialogDisclosure
        className="form__dialog__toggle"
        aria-label="Open info dialog"
        data-spec-dialog-toggle
      ></DialogDisclosure>
      <Dialog
        className={String(
          typeof document === "undefined"
            ? ""
            : document.querySelector(".page")?.getAttribute("class"),
        )}
      >
        <div className="dialog">
          <div className="dialog__body">
            <DialogHeading className="dialog__title">
              {headerText || "Why are we asking?"}
            </DialogHeading>
            <div className="dialog__content">{text}</div>
            <DialogDismiss className="button button--is-outlined">
              <span className="button__content">
                <span className="button__text">Close</span>
              </span>
            </DialogDismiss>
            <DialogDismiss className="dialog__close">
              <FontAwesomeIcon icon={faClose} />
              <div className="sr-only">Close info dialog</div>
            </DialogDismiss>
          </div>
        </div>
      </Dialog>
    </DialogProvider>
  );
}

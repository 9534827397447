"use client";

import { useEffect } from "react";

import {
  Neverbounce,
  handleNeverbounce,
} from "@client/components/analytics/components/neverbounce";
import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";

export default function Email() {
  handleNeverbounce();

  return (
    <>
      <Input type="hidden" name="agreesToDataSharing" />
      <Input type="hidden" name="agreesToSoftPull" />
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Fieldset
        dialog="We request your email to provide essential updates, timely communication, and access to your personalized debt solutions. We're committed to not spamming and keeping your email information protected."
        legend="What is your email?"
      >
        <Field label="Email" name="email">
          <Input type="email" name="email" className="w-full" />
        </Field>
      </Fieldset>
      <Button />
      <Neverbounce />
    </>
  );
}

"use client";

import { FieldAttributes, useFormikContext } from "formik";

import Input from "@client/components/formik/input";

export default function FormikZipCode(props: FieldAttributes<any>) {
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      "zipCode",
      e.target.value.replaceAll(/[^0-9]/g, "").slice(0, 5),
    );
  };

  return <Input name="zipCode" inputMode="numeric" onChange={handleOnChange} />;
}

"use client";

import { FieldAttributes, useField } from "formik";

import Input from "@ui/js/components/form/input";

export default function FormikInput(props: FieldAttributes<any>) {
  const [field] = useField(props);

  return <Input {...field} {...props} />;
}

type RecursiveObject = {
  [key: string]: boolean | string | RecursiveObject | RecursiveObject[];
};

export default function searchAndReplace(
  obj: string | RecursiveObject | RecursiveObject[],
  replacements: { [key: string]: string },
): boolean | string | RecursiveObject | RecursiveObject[] {
  // Precompile all necessary regexes
  const regexes = Object.keys(replacements).map((key) => ({
    regex: new RegExp(`\\{\\{${key}\\}\\}`, "g"),
    replace: replacements[key] || "",
  }));

  return deepReplace(obj, regexes);
}

// Helper function to apply replacements recursively
function deepReplace(
  obj: boolean | string | RecursiveObject | RecursiveObject[],
  regexes: { regex: RegExp; replace: string }[],
): boolean | string | RecursiveObject | RecursiveObject[] {
  if (typeof obj === "string") {
    // Apply all replacements in a single pass
    return regexes.reduce(
      (str, { regex, replace }) => str.replace(regex, replace),
      obj,
    );
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepReplace(item, regexes)) as RecursiveObject[];
  }

  if (typeof obj === "object" && obj !== null) {
    const result: RecursiveObject = {};
    Object.keys(obj).forEach((key) => {
      result[key] = deepReplace(obj[key], regexes);
    });
    return result;
  }

  return obj;
}

"use client";

import { FieldAttributes, useField } from "formik";

import Select from "@ui/js/components/form/select";

export default function FormikSelect(props: FieldAttributes<any>) {
  const [field] = useField(props);

  return <Select {...field} {...props} />;
}

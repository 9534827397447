"use client";

import { Field, useFormikContext } from "formik";

import Fieldset from "@client/components/lead-form/components/fieldset";

import Button from "@ui/js/components/form/button";

import type ButterCMS from "@packages/types/buttercms";

type Props = {
  heroStepInfo: ButterCMS.InfoSection.EditableHeroQuestion;
};

export default function ButterCMSEditable({ heroStepInfo }: Props) {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend={heroStepInfo.label}>
      <Field type="hidden" name="butterCMSEditable" id="butter-cms-editable" />
      {heroStepInfo.question_tiles.map((tile, index) => {
        return (
          <Button
            key={index}
            chevron={true}
            iconLeft={
              <i
                className={`fa-solid ${tile.icon} text-primary text-lg`}
                aria-hidden
              ></i>
            }
            text={tile.text}
            onClick={() => setFieldValue("butterCMSEditable", tile.value)}
          />
        );
      })}
    </Fieldset>
  );
}

"use client";

import { useFormikContext } from "formik";
import { useEffect } from "react";

import { EVENT_ERROR } from "@shared/constants";

import DataLayer from "@client/classes/data-layer/data-layer";

export default function LeadFormValidationError() {
  const { errors } = useFormikContext<any>();

  useEffect(() => {
    if (Object.keys(errors).length < 1) {
      return;
    }

    DataLayer.events.trigger(EVENT_ERROR, {
      message: Object.keys(errors).reduce(
        (pv, cv) => (pv ? pv + "\n" : "") + errors[cv],
        "",
      ),
      object: "lead-form",
      type: "validation",
    });
  }, [errors]);

  return null;
}

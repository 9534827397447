"use client";

import {
  faCoins,
  faMoneyBill,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import { faCoin } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";
import Fieldset from "@client/components/lead-form/components/fieldset";

import Button from "@ui/js/components/form/button";

export default function DebtAmount() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="How much debt do you owe?">
      <Input type="hidden" name="debtAmount" id="debt-amount" />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faCoin} className="text-primary text-lg" />
        }
        text="$10k - $20k"
        onClick={() => setFieldValue("debtAmount", "$10k_to_$20k")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faCoins} className="text-primary text-lg" />
        }
        text="$20k - $40k"
        onClick={() => setFieldValue("debtAmount", "$20k_to_$40k")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon
            icon={faMoneyBill}
            className="text-primary text-lg"
          />
        }
        text="$40k - $100k"
        onClick={() => setFieldValue("debtAmount", "$40k_to_$100k")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon
            icon={faSackDollar}
            className="text-primary text-lg"
          />
        }
        text="Over $100k"
        onClick={() => setFieldValue("debtAmount", "over_$100k")}
      />
    </Fieldset>
  );
}

"use client";

import {
  faCar,
  faHome,
  faMoneyBillWave,
  faPiggyBank,
  faUmbrellaBeach,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, useFormikContext } from "formik";

import Fieldset from "@client/components/lead-form/components/fieldset";

import Button from "@ui/js/components/form/button";

export default function financialGoal() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="What would you do with extra monthly savings?">
      <Field type="hidden" name="financialGoal" id="financial-goal" />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faMoneyBillWave} className="text-primary" />
        }
        text="Pay off my debt faster"
        onClick={() => setFieldValue("financialGoal", "pay_off_debt_faster")}
      />
      <Button
        chevron={true}
        iconLeft={<FontAwesomeIcon icon={faHome} className="text-primary" />}
        text="Purchase a home"
        onClick={() => setFieldValue("financialGoal", "purchase_a_home")}
      />
      <Button
        chevron={true}
        iconLeft={<FontAwesomeIcon icon={faCar} className="text-primary" />}
        text="Buy a vehicle"
        onClick={() => setFieldValue("financialGoal", "buy_a_vehicle")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faPiggyBank} className="text-primary" />
        }
        text="Save or invest"
        onClick={() => setFieldValue("financialGoal", "save_or_invest")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faUmbrellaBeach} className="text-primary" />
        }
        text="Enjoy flexible spending"
        onClick={() => setFieldValue("financialGoal", "flexible_spending")}
      />
    </Fieldset>
  );
}

"use client";

import { useFormikContext } from "formik";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

export default function NurseSpecialty() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("nurseSpecialty", e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="What is your nursing specialty?">
        <Field label="Nurse specialty" name="nurseSpecialty">
          <Select name="nurseSpecialty" onChange={handleChange}>
            <option value="" disabled>
              Select a specialty
            </option>
            <option value="RN">Registered Nurse (RN)</option>
            <option value="LPN">Licensed Practical Nurse (LPN)</option>
            <option value="CNA">Certified Nursing Assistant (CNA)</option>
            <option value="APRN">
              Advanced Practice Registered Nurse (APRN)
            </option>
            <option value="nurse_educator">Nurse Educator</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}

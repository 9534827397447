import React, { Component } from "react";

import {
  TRUST_PILOT_BUSINESS_UNIT_ID,
  TRUST_PILOT_URL,
} from "@shared/constants";

// Copied from:
// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#add-a-trustbox-widget-with-a-react-application-1

const TrustBox = ({
  trustBoxRef,
}: {
  trustBoxRef: React.RefObject<HTMLDivElement>;
}) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    className="trustpilot-widget h-6 w-full"
    data-locale="en-US"
    data-template-id="5419b732fbfb950b10de65e5"
    data-businessunit-id={TRUST_PILOT_BUSINESS_UNIT_ID}
    data-style-height="24px"
    data-style-width="100%"
  >
    <a href={TRUST_PILOT_URL} target="_blank" rel="noopener noreferrer">
      Trustpilot
    </a>
  </div>
);

class TrustBoxContainer extends Component {
  trustBoxRef: React.RefObject<HTMLDivElement>;
  constructor(props: {}) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />;
  }
}
export default TrustBoxContainer;

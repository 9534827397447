"use client";

import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";
import Fieldset from "@client/components/lead-form/components/fieldset";

import Button from "@ui/js/components/form/button";

export default function CreditScore() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="What is your estimated credit score?">
      <Input type="hidden" name="creditScore" id="credit-score" />
      <Button
        chevron={true}
        text="Excellent 720-850"
        onClick={() => setFieldValue("creditScore", "excellent")}
      />
      <Button
        chevron={true}
        text="Good 680-719"
        onClick={() => setFieldValue("creditScore", "good")}
      />
      <Button
        chevron={true}
        text="Fair 640-679"
        onClick={() => setFieldValue("creditScore", "fair")}
      />
      <Button
        chevron={true}
        text="Poor 0 - 639"
        onClick={() => setFieldValue("creditScore", "poor")}
      />
    </Fieldset>
  );
}

"use client";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import { useCMSOverride } from "@client/functions/custom-hooks/useCMSOverride";

type Props = {
  legendOverride?: string;
};

export default function StreetAddress({ legendOverride }: Props) {
  const legendFallback = "What is your street address?";
  const legend = useCMSOverride(legendFallback, legendOverride);

  return (
    <>
      <Fieldset
        dialog="We request your address for three main reasons: to verify your identity, to conduct an accurate soft credit pull without affecting your score, and to match you with the best financial solutions. Your privacy is paramount to us; all data is encrypted and used with the utmost care."
        legend={legend}
      >
        <Field label="Street Address" name="streetAddress">
          <Input name="streetAddress" />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}

"use client";

import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";

type Props = {
  name: string;
};

export default function FormikCurrency({ name }: Props) {
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      name,
      Number(e.target.value.replaceAll(/[^0-9]/g, "")).toLocaleString(),
    );
  };

  return (
    <div className="flex gap-2">
      <span>$</span>
      <Input
        name={name}
        inputMode="numeric"
        className="flex-1"
        onChange={handleOnChange}
      />
    </div>
  );
}

"use client";

import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";

export default function FormikPhoneNumber() {
  const { setFieldValue } = useFormikContext<any>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replaceAll(/[^0-9]/g, "").slice(0, 10);

    if (value.length === 10) {
      value = value.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    } else if (value.length > 6) {
      value = value.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    } else if (value.length > 3) {
      value = value.replace(/(\d{3})(\d{0,3})/, "$1-$2");
    }

    setFieldValue("phoneNumber", value);
  };

  return (
    <Input
      type="tel"
      name="phoneNumber"
      inputMode="numeric"
      placeholder="xxx-xxx-xxxx"
      onChange={handleOnChange}
    />
  );
}

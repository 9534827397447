"use client";

import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";

export default function FormikDateOfBirth() {
  const { setFieldValue } = useFormikContext<any>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replaceAll(/[^0-9]/g, "").slice(0, 8);

    if (value.length === 8) {
      value = value.replace(/(\d{2})(\d{2})(\d+)/, "$1/$2/$3");
    } else if (value.length > 4) {
      value = value.replace(/(\d{2})(\d{2})(\d+)/, "$1/$2/$3");
    } else if (value.length > 2) {
      value = value.replace(/(\d{2})(\d{0,2})/, "$1/$2");
    }

    setFieldValue("dob", value);
  };

  return (
    <Input
      name="dob"
      inputMode="numeric"
      placeholder="MM/DD/YYYY"
      onChange={handleOnChange}
    />
  );
}

import clsx from "clsx";

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
  name: string;
};

export default function Input({ className, type, ...props }: Props) {
  type = type || "text";

  if (!props.id) {
    // convert camelCase to kebab-case
    props.id = props.name.replace(/([A-Z])/g, "-$1").toLowerCase();
  }

  return (
    <input
      type={type}
      className={clsx(`form__input form__input_type_${type}`, className)}
      required={true}
      {...props}
    />
  );
}

"use client";

import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, useFormikContext } from "formik";

import Button from "@ui/js/components/form/button";
import Fieldset from "@ui/js/components/form/fieldset";

export default function StudentLoanDebt() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="Do you currently have student loan debt?">
      <Field type="hidden" name="studentLoanDebt" id="student-loan-debt" />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faThumbsUp} className="text-primary" />
        }
        text="Yes"
        onClick={() => setFieldValue("studentLoanDebt", "yes")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faThumbsDown} className="text-primary" />
        }
        text="No"
        onClick={() => setFieldValue("studentLoanDebt", "no")}
      />
    </Fieldset>
  );
}

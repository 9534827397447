"use client";

import DOB from "@client/components/formik/date-of-birth";
import Field from "@client/components/formik/field";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";

export default function DateOfBirth() {
  return (
    <>
      <Fieldset
        dialog="We ask for your date of birth to ensure accurate identity verification and to tailor financial solutions based on age-related eligibility. This detail aids in providing you the most relevant offers. As with all personal data, we treat this information with the highest level of security and discretion."
        legend="What is your date of birth?"
      >
        <Field label="Date of Birth" name="dob">
          <DOB />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}

"use client";

import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";

import Input from "@client/components/formik/input";

import Button from "@ui/js/components/form/button";
import Fieldset from "@ui/js/components/form/fieldset";

export default function LoanApplicationHistory() {
  const { setFieldValue } = useFormikContext();

  return (
    <Fieldset legend="Have you recently applied for a loan and either been declined or approved for an amount smaller than what you need?">
      <Input
        type="hidden"
        name="loanApplicationHistory"
        id="loan-application-history"
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon icon={faThumbsUp} className="text-primary text-lg" />
        }
        text="Yes"
        onClick={() => setFieldValue("loanApplicationHistory", "Yes")}
      />
      <Button
        chevron={true}
        iconLeft={
          <FontAwesomeIcon
            icon={faThumbsDown}
            className="text-primary text-lg"
          />
        }
        text="No"
        onClick={() => setFieldValue("loanApplicationHistory", "No")}
      />
    </Fieldset>
  );
}

"use client";

import { useFormikContext } from "formik";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

export default function MilitaryBranch() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("militaryBranch", e.currentTarget.value);
  };

  return (
    <>
      <Fieldset
        legend={
          <>
            <span className="form__legend__text">
              Thank you for your service. Which branch were you?
            </span>
            <span className="block mt-3 text-xs">
              Your answer does not affect program eligibility.
            </span>
          </>
        }
      >
        <Field label="U.S. Military Branch" name="militaryBranch">
          <Select name="militaryBranch" onChange={handleChange}>
            <option value="" disabled>
              Select a branch
            </option>
            <option value="army">U.S. Army</option>
            <option value="navy">U.S. Navy</option>
            <option value="air_force">U.S. Air Force</option>
            <option value="marine_corps">U.S. Marine Corps</option>
            <option value="coast_guard">U.S. Coast Guard</option>
            <option value="national_guard">U.S. National Guard</option>
            <option value="space_force">U.S. Space Force</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}

"use client";

import { useFormikContext } from "formik";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

export default function ReasonEnlisted() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("reasonEnlisted", e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="Thank you for your service. Why did you enlist?">
        <Field label="Reason Enlisted" name="reasonEnlisted">
          <Select name="reasonEnlisted" onChange={handleChange}>
            <option value="" disabled>
              Select a reason
            </option>
            <option value="patriotism">Patriotism</option>
            <option value="family_tradition">Family Tradition</option>
            <option value="education_career">
              Educational/Career Opportunities
            </option>
            <option value="adventure_travel">Adventure/Travel</option>
            <option value="personal_growth">Personal Growth</option>
            <option value="making_a_difference">Making a Difference</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
